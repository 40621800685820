import React from "react";

export const CompositionsContext = React.createContext({
    activeFrame: null,
    compositionPreview: null,
    currentComp: null,
    missingImages: 0,
    minVoucherAmount: 50,
    maxVoucherAmount: 5000,
    lowDPI: false,
    passepartoutMismatch: {},
    filteredImages: null,
    blockUpdateFilteredImages: false,
    parsedComps: [],
    orderedImagesId: [],
    lastNonVoucherComposition: null,
    voucherMismatch: false,
    isInEditMode: false,
    setIsInEditMode: () => {},
    addImagesToFrames: () => {},
    copyComposition: () => {},
    pushComposition: () => {},
    resetFrame: () => {},
    createEmptyComposition: () => {},
    generateCompositionId: () => {},
    createCompositionFromTemplate: () => {},
    fetchComps: () => {},
    handleCreateVoucher: () => {},
    getOrCreateVoucher: () => {},
    goBackToLastNonVoucherComposition: () => {},
    removeImageFromFrame: () => {},
    setActiveFrame: () => {},
    setActiveFrames: () => {},
    setSelectedFrame: () => {},
    setCompositionPreview: () => {},
    setCurrentComp: () => {},
    setLastNonVoucherComposition: () => {},
    setVoucherMismatch: () => {},
    setParsedComps: () => {},
    prepareFilteredImages: () => {},
    setFilteredImages: () => {},
    setBlockUpdateFilteredImages: () => {},
    swapImages: () => {},
    editModeUpdateFrames: () => {},
    updateCompSize: () => {},
    updateCompFavourite: () => {},
    updateCompHangingSystem: () => {},
    updateCompLUTFilter: () => {},
    updateCompMaterial: () => {},
    updateCompPrice: () => {},
    updateCompPassepartout: () => {},
    updateCompPaper: () => {},
    updateFrameMaterial: () => {},
    updateFramePassepartout: () => {},
    updatePredefinedFrameStyle: () => {},
    updateFrames: () => {},
    usedImageFilenamesSet: () => {},
    updateFramesCalculated: () => {},
});

export function useComposition() {
    return React.useContext(CompositionsContext);
};
