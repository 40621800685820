import { zeros } from "mathjs";
import md5 from "md5";
import { Munkres } from "munkres-js";

import { EMAILVOUCHER } from "../data/constants";
import {
    calculateCropZoom,
    calculateCropZoomLimit,
    calculateDpi,
    getNormalizedFrameSize,
    getPassepartoutOptions,
} from './frame';
import { generateCombinations, generateRandomId } from './helpers';


const FEED_FRAMES_COUNT_GROUPS = [
    { min: 1, max: 7, group: '1-7' },
    { min: 8, max: 11, group: '8-11' },
    { min: 12, max: 14, group: '12-14' },
    { min: 15, max: 25, group: '15+' },
];


let croppingBase = {};

export function updateCrop(image, frame, reset = true) {
    if (!frame) {
        return '0,0,1,1';
    }
    if (!image) {
        return frame.cropping;
    }

    const passepartout = frame.passepartout_width_mm;
    const imageWidth = frame.calculated.image_width - 2 * passepartout;
    const imageHeight = frame.calculated.image_height - 2 * passepartout;
    const originalWidth = image.width;
    const originalHeight = image.height;
    const aspectRatio = imageWidth / imageHeight;

    let cropWidth, cropHeight, startX, startY, endX, endY;

    if (reset) {
        cropWidth = originalWidth;
        cropHeight = originalWidth / aspectRatio;
        if (cropHeight > originalHeight) {
            cropWidth = originalHeight * aspectRatio;
            cropHeight = originalHeight;
        }
        startX = Math.round((originalWidth - cropWidth) / 2);
        startY = Math.round((originalHeight - cropHeight) / 2);
    } else {
        const croppingArray = frame.cropping.split(",");
        const currentCropWidth = parseInt(croppingArray[2]) - parseInt(croppingArray[0]);
        const currentCropHeight = parseInt(croppingArray[3]) - parseInt(croppingArray[1]);

        if (croppingBase[frame.id] === 'height') {
            cropWidth = currentCropHeight * aspectRatio;
            cropHeight = currentCropHeight;
        } else {
            cropWidth = currentCropWidth;
            cropHeight = currentCropWidth / aspectRatio;
        }
        if (cropWidth > originalWidth) {
            cropWidth = originalWidth;
            cropHeight = originalWidth / aspectRatio;
            croppingBase[frame.id] = 'width'
        }
        if (cropHeight > originalHeight) {
            cropWidth = originalHeight * aspectRatio;
            cropHeight = originalHeight;
            croppingBase[frame.id] = 'height'
        }

        startX = parseInt(croppingArray[0]) + Math.round((currentCropWidth - cropWidth) / 2);
        startY = parseInt(croppingArray[1]) + Math.round((currentCropHeight - cropHeight) / 2);
    }

    if (image.crop_center_point) {
        startX = Math.round(image.crop_center_point[0] - cropWidth / 2);
        startY = Math.round(image.crop_center_point[1] - cropHeight / 2);
    }

    endX = Math.round(startX + cropWidth);
    endY = Math.round(startY + cropHeight);

    if (startX < 0) {
        endX -= startX;
        startX = 0;
    }
    if (startY < 0) {
        endY -= startY;
        startY = 0;
    }
    if (endX > originalWidth) {
        startX -= (endX - originalWidth);
        endX = originalWidth;
    }
    if (endY > originalHeight) {
        startY -= (endY - originalHeight);
        endY = originalHeight;
    }

    return `${startX},${startY},${endX},${endY}`;
}

export function calculateCroppingFromZoom(frame, image, zoom) {
    const crop = frame.cropping.split(',').map(Number);
    const originalCropWidth = crop[2] - crop[0];
    const originalCropHeight = crop[3] - crop[1];
    const cropCenterX = Math.round(crop[0] + originalCropWidth / 2);
    const cropCenterY = Math.round(crop[1] + originalCropHeight / 2);

    const originalWidth = image.width;
    const originalHeight = image.height;

    const newWidth = Math.round((originalCropWidth / zoom) * frame.calculated.crop_zoom);
    const newHeight = Math.round((originalCropHeight / zoom) * frame.calculated.crop_zoom);

    let dx = Math.round(newWidth / 2);
    let dy = Math.round(newHeight / 2);

    let startX = cropCenterX - dx;
    let startY = cropCenterY - dy;
    let endX = cropCenterX + dx;
    let endY = cropCenterY + dy;

    if (startX < 0) {
        startX = 0;
        endX = Math.min(newWidth, originalWidth);
    }
    if (startY < 0) {
        startY = 0;
        endY = Math.min(newHeight, originalHeight);
    }
    if (endX > originalWidth) {
        endX = originalWidth;
        startX = Math.max(originalWidth - newWidth, 0);
    }
    if (endY > originalHeight) {
        endY = originalHeight;
        startY = Math.max(originalHeight - newHeight, 0);
    }
    return `${startX},${startY},${endX},${endY}`;
}

const sortImages = (images) => {
    const imagesWithFrameSize = images.filter(image => image.frame_size);
    const imagesWithoutFrameSize = images.filter(image => !image.frame_size);

    const sortedImagesWithFrameSize = imagesWithFrameSize.sort((a, b) => {
        const areaA = a.frame_size[0] * a.frame_size[1];
        const areaB = b.frame_size[0] * b.frame_size[1];

        return areaB - areaA;
    });

    const sortedImagesWithoutFrameSize = imagesWithoutFrameSize.sort((a, b) => {
        const areaA = a.width * a.height;
        const areaB = b.width * b.height;

        return areaB - areaA;
    });
    return sortedImagesWithFrameSize.concat(sortedImagesWithoutFrameSize);
};

const sortFrames = (frames) => {
    return frames.sort((a, b) => {
        const areaA = a.calculated.image_width * a.calculated.image_height;
        const areaB = b.calculated.image_width * b.calculated.image_height;

        return areaB - areaA;
    });
};

export function matchImagesWithFrames(composition, images, frameId = null) {

    if (!images || images.length === 0) {
        return composition.frames_json.map((frame) => frame);
    }

    const sortedImages = sortImages(images);

    const unassignedFrames = composition?.frames_json?.filter((frame) => !frame.image) ?? [];
    const sortedFrames = sortFrames(unassignedFrames);

    const assignedFrames = []

    if (frameId) {
        const targetFrameIndex = sortedFrames.findIndex(frame => frame.id === frameId);
        if (targetFrameIndex !== -1 && sortedImages.length > 0) {
            const targetFrame = sortedFrames.splice(targetFrameIndex, 1)[0];
            const targetImage = sortedImages.shift();

            const updatedFrame = {
                ...targetFrame,
                image: targetImage.filename,
                cropping: updateCrop(targetImage, targetFrame)
            };
            assignedFrames.push({
                ...updatedFrame,
                calculated: {
                    ...targetFrame.calculated,
                    dpi: calculateDpi(updatedFrame, 15.5),
                    crop_zoom: calculateCropZoom(updatedFrame, targetImage),
                    crop_zoom_min: calculateCropZoomLimit(updatedFrame, targetImage, 15.5)
                }
            });
        }
    }

    if (sortedFrames.length === 0 || sortedImages.length === 0) {
        return composition.frames_json.map((frame) => {
            const assignedFrame = assignedFrames.find((af) => af.id === frame.id);
            return assignedFrame || frame;
        });
    }

    const numImages = sortedImages.length;
    const numFrames = sortedFrames.length;

    const xDpi = zeros([numImages, numFrames]);
    const yDpi = zeros([numImages, numFrames]);
    const ratioDiff = zeros([numImages, numFrames]);

    for (let fi = 0; fi < numFrames; fi++) {
        const f = sortedFrames[fi];
        for (let ii = 0; ii < numImages; ii++) {
            const i = sortedImages[ii];

            xDpi[ii][fi] = i.width / (f.calculated.image_width / 25.4);
            yDpi[ii][fi] = i.height / (f.calculated.image_height / 25.4);

            let factor = 100;

            if (i.frame_size) {
                const fi_area = f.calculated.frame_width * f.calculated.frame_height;
                const ii_area = i.frame_size[0] * i.frame_size[1];

                const diff = Math.abs(fi_area - ii_area);

                if (diff === 0) {
                    factor = 0.1;
                } else if (diff > fi_area) {
                    factor = diff / fi_area;
                } else if (diff < fi_area) {
                    factor = fi_area / diff;
                }
            }
            ratioDiff[ii][fi] = Math.abs(i.width / i.height - f.calculated.image_width / f.calculated.image_height) * factor;
        }
    }

    xDpi.forEach((image, ii) => {
        image.forEach((dpi, fi) => {
            if (dpi >= 150 && dpi < 300) {
                ratioDiff[ii][fi] = ratioDiff[ii][fi] * 3;
            }
            else if (dpi < 150) {
                ratioDiff[ii][fi] = ratioDiff[ii][fi] * 10;
            }
        })
    });

    yDpi.forEach((image, ii) => {
        image.forEach((dpi, fi) => {
            if (dpi >= 150 && dpi < 300) {
                ratioDiff[ii][fi] = ratioDiff[ii][fi] * 3;
            }
            else if (dpi < 150) {
                ratioDiff[ii][fi] = ratioDiff[ii][fi] * 10;
            }
        })
    });

    const m = new Munkres();
    const indices = m.compute(ratioDiff);

    const result = {};

    indices.forEach(([row, col]) => {
        const frame = sortedFrames[col];
        if (!result.hasOwnProperty(frame.frame_size)) {
            result[frame.frame_size] = new Set();
        }
        result[frame.frame_size].add(sortedImages[row]);
    });

    const usedImages = {};

    const updatedFrames = unassignedFrames.map(frame => {
        if (frame.image) {
            return frame;
        }

        const id = frame.frame_size;
        if (result.hasOwnProperty(id)) {
            const images = Array.from(result[id]);

            if (!usedImages.hasOwnProperty(id)) {
                usedImages[id] = 0;
            }

            if (usedImages[id] < images.length) {
                const image = images[usedImages[id]];
                usedImages[id] += 1;
                const updatedFrame = { ...frame, image: image.filename, cropping: updateCrop(image, frame) };
                const cropZoomLimit = calculateCropZoomLimit(updatedFrame, image, 15.5);
                const calculated = {
                    ...updatedFrame.calculated,
                    dpi: calculateDpi(updatedFrame, 15.5),
                    crop_zoom: Math.max(calculateCropZoom(updatedFrame, image), cropZoomLimit),
                    crop_zoom_min: cropZoomLimit
                };

                return { ...updatedFrame, calculated: calculated };
            }
        }

        return { ...frame, image: null };
    });

    const mergedFrames = composition.frames_json.map((frame) => {
        const assignedFrame = assignedFrames.find((af) => af.id === frame.id);
        const updatedFrame = updatedFrames.find((uf) => uf.id === frame.id);
        return assignedFrame || updatedFrame || frame;
    }) ?? [];

    return mergedFrames;
}

function findFrameSizeById(frameSizes, frameId) {
    return frameSizes.get(frameId);
}

export function convertTemplateToFrames(template, frameSizes) {
    if (Array.isArray(template.frames)) {
        const framesWithId = template.frames.map(frame => ({
            ...frame,
            id: generateRandomId()
        }));
        return framesWithId;
    }
    const frames = [];
    Object.keys(template.frames).forEach((key) => {
        const framePositions = template.frames[key];
        const frameSize = findFrameSizeById(frameSizes, key);
        if (frameSize) {
            const { width, height } = frameSize;
            framePositions.forEach((position) => {
                const id = generateRandomId()
                const [x, y] = position;
                const frame = { id, x, y, width, height, frame_size: frameSize.id };
                frames.push(frame);
            });
        }
    });
    return frames;
}


export function calculateSizeWithoutExternalPadding(frames) {
    let x1 = frames.reduce((min, f) => Math.min(min, f.x), Infinity);
    let y1 = frames.reduce((max, f) => Math.max(max, f.y), -Infinity);
    let x2 = frames.reduce((max, f) => Math.max(max, f.x + f.width), -Infinity);
    let y2 = frames.reduce((min, f) => Math.min(min, f.y - f.height), Infinity);

    const recalculatedFrames = frames.map((f) => {
        return { ...f, x: f.x - x1, y: f.y - y2 };
    });

    const width = x2 - x1;
    const height = y1 - y2;
    return { recalculatedFrames, width, height };
};

export function sortFramesByPosition(frames) {
    return frames.sort((a, b) => {
        if (a.x === b.x) {
            return b.y - a.y;
        }
        return a.x - b.x;
    });
}


export const sortFramesByPositionCenter = frames => {
        return frames.sort((a, b) => {
            const a_x = a.x + (a.width || a.calculated.frame_width || 0) / 2;
            const b_x = b.x + (b.width || b.calculated.frame_width || 0) / 2;
            return a_x !== b_x ? a_x - b_x : (b.y - (b.height || b.calculated.frame_height) / 2) - (a.y - (a.height || a.calculated.frame_height) / 2);
        });
    };


export const getCompositionOffsetFromOrigin = (frames) => {
    if (!frames) {
        return [0, 0]
    }
    const offsetX = Math.min(...frames.map((el) => {
        return el.x
    }))
    const offsetY = Math.min(...frames.map((el) => {
        return el.y - el.calculated.frame_height
    }))
    return [offsetX, offsetY]
}

export const getCompositionMaxCoordinates = (frames) => {
    if (!frames) {
        return [0, 0]
    }
    const maxX = Math.max(...frames.map((el) => {
        return el.x + el.calculated.frame_width
    }))
    const maxY = Math.max(...frames.map((el) => {
        return el.y
    }))
    return [maxX, maxY]
}

export const moveToOrigin = (frames) => {
    if (!frames) {
        return
    }
    const [offsetX, offsetY] = getCompositionOffsetFromOrigin(frames)
    const [width, height] = getCompositionSize(frames)
    return frames.map((el) => {
        return { ...el, x: el.x - offsetX, y: el.y - offsetY, frame_left: el.frame_left - offsetX, frame_top: height - (el.y - offsetY) }
    })
}

export const getCompositionSize = (frames) => {
    const [maxX, maxY] = getCompositionMaxCoordinates(frames)
    const [minX, minY] = getCompositionOffsetFromOrigin(frames)
    return [maxX - minX, maxY - minY]
}

export function getCompositionArea(composition) {
    if (!composition || !composition?.width) return 0;
    return composition.width * composition.height;
}

export function compareFrames(currentComp, frames) {
    const diff = {
        frames: frames,
        images: frames,
        cardboard_passepartouts: frames,
    };

    if (currentComp?.baseline_data) {
        function getHash(frame, parameter = 'frame_material', excluded) {
            frame = frameToHash(frame, parameter, excluded);
            return md5(JSON.stringify(frame));
        }

        function frameToHash(frame, parameter = 'frame_material', excluded) {
            let frameParams;
            if (parameter === 'image') {
                frameParams = {
                    image: frame.image,
                    frame_size: frame.frame_size,
                    // cropping: frame.cropping,
                    passepartout: frame.passepartout_width_mm,
                    passepartout_color: frame.cardboard_passepartout ? '#FFFFFF' : frame.passepartout_color,
                };
            } else if (parameter === 'cardboard_passepartout') {
                frameParams = {
                    id: frame.id,
                    frame_size: frame.frame_size,
                    cardboard_passepartout: frame.cardboard_passepartout,
                };
            } else {
                frameParams = {
                    id: frame.id,
                    frame_size: frame.frame_size,
                    frame_material: frame.frame_material,
                };
            }
            if (parameter in excluded) {
                for (let excludedParameter of excluded[parameter]) {
                    delete frameParams[excludedParameter];
                }
            }
            return frameParams;
        }

        function compareFramesByParameter(parameter = 'frame_material') {
            const original = currentComp.baseline_data.frames_json.map((frame) => {
                const newFrame = frames.find((f) => f.id === frame.id);
                const excluded = newFrame?.cardboard_passepartout ? { 'image': ['passepartout_color'] } : {};
                return getHash(frame, parameter, excluded)
            });
            const copy = frames
                .filter((frame) => !(frame.image === null && currentComp.baseline_data.frames_json.some((f) => f.id === frame.id) && parameter === 'image'))
                .filter((frame) => !(frame.cardboard_passepartout === null && parameter === 'cardboard_passepartout'))
                .map((frame) => {
                    const excluded = frame.cardboard_passepartout ? { 'image': ['passepartout_color'] } : {};
                    return ({ [getHash(frame, parameter, excluded)]: frame })
                });
            return copy.filter((item) => !original.includes(Object.keys(item)[0])).map((item) => Object.values(item)[0]);
        }

        diff.frames = compareFramesByParameter('frame_material');
        diff.images = compareFramesByParameter('image');
        diff.cardboard_passepartouts = compareFramesByParameter('cardboard_passepartout');
    }

    return diff;
}

export function checkPassepartoutWidths(frames, padding) {
    const hasSamePassepartout = (frame1, frame2) => frame1.passepartout_width_mm === frame2.passepartout_width_mm;

    for (let i = 0; i < frames.length; i++) {
        const frame1 = frames[i];
        const frame1Width = frame1.calculated.frame_width;
        const frame1Height = frame1.calculated.frame_height;

        for (let j = i + 1; j < frames.length; j++) {
            const frame2 = frames[j];
            const frame2Width = frame2.calculated.frame_width;
            const frame2Height = frame2.calculated.frame_height;

            // Check if frames are horizontally adjacent
            if (frame1.y === frame2.y &&
                Math.abs(frame1.x - frame2.x) === frame1Width + 2 * padding &&
                frame1Height === frame2Height) {
                if (!hasSamePassepartout(frame1, frame2)) {
                    return false;
                }
            }

            // Check if frames are vertically adjacent
            if (frame1.x === frame2.x &&
                Math.abs(frame1.y - frame2.y) === frame1Height + 2 * padding &&
                frame1Width === frame2Width) {
                if (!hasSamePassepartout(frame1, frame2)) {
                    return false;
                }
            }
        }
    }
    return true;
}

const validatePassepartoutOrder = (frames) => {
    const areas = frames.map(frame => ({
        area: frame.calculated.frame_width * frame.calculated.frame_height,
        passepartout_width: frame.passepartout_width_mm
    }));

    areas.sort((a, b) => a.area - b.area);

    for (let i = 1; i < areas.length; i++) {
        if (areas[i].passepartout_width < areas[i - 1].passepartout_width) {
            return false;
        }
    }
    return true;
}

export function findOptimalPassepartoutLayout(frames) {
    const frameSizeOptions = {}
    for (let frame of frames) {
        const frameSize = getNormalizedFrameSize(frame);
        if (!frameSizeOptions[frameSize]) {
            frameSizeOptions[frameSize] = getPassepartoutOptions(frame)
        }
    }

    const possibilities = Object.values(frameSizeOptions);
    const keys = Object.keys(frameSizeOptions);
    const allCombinations = generateCombinations(possibilities);

    const validCombinations = allCombinations.map((combination) => {
        return frames.map((frame) => ({
            ...frame,
            passepartout_width_mm: combination[keys.indexOf(getNormalizedFrameSize(frame))]
        }));
    }).filter((frames) => checkPassepartoutWidths(frames, 25) && validatePassepartoutOrder(frames));

    return selectBestPassepartoutLayout(frames, validCombinations);
}

const selectBestPassepartoutLayout = (frames, validCombinations) => {
    let bestVariant = null;
    let bestScore = Infinity;

    for (let variant of validCombinations) {
        let score = 0;
        for (let i = 0; i < frames.length; i++) {
            if (frames[i].passepartout_width_mm !== variant[i].passepartout_width_mm) {
                const perimeter = 2 * (frames[i].calculated.image_width + frames[i].calculated.image_height);
                score += Math.abs(frames[i].passepartout_width_mm - variant[i].passepartout_width_mm) * perimeter
            }
        }
        if (score < bestScore) {
            bestScore = score;
            bestVariant = variant;
        }
    }
    return bestVariant;
}

export const assignFrameCountGroup = (framesCount) => {
    for (const interval of FEED_FRAMES_COUNT_GROUPS) {
        if (interval.min <= framesCount && framesCount <= interval.max) {
            return interval.group;
        }
    }
    return null;
};

export const getLUTFiltercategory = (lutFilter) => {
    let lutFilterCategory = lutFilter?.category;
    if (lutFilterCategory === 'SEPIA' || !lutFilterCategory) {
        lutFilterCategory = 'COLOR';
    }
    return lutFilterCategory;
}

export const hasMargin = (passepartoutPreset) => {
    return passepartoutPreset === "None" ? "F" : "T"
}

export const createVoucher = (currentFrami) => {
    return {
        id: null,
        frami: currentFrami.id,
        passepartout: 0,
        width: 170,
        height: 170,
        currency: currentFrami.currency,
        paper: currentFrami.default_paper,
        hanging_system: 'F',
        lut_filter: "NONE",
        favourite: false,
        frame_area: 0,
        is_voucher: true,
        voucher_amount: 0,
        voucher_amount_currency: currentFrami.currency,
        voucher_type: EMAILVOUCHER,
        material_replacement: false,
        price: {
            frame_price: 0,
            passepartout_price: 0,
            print_price: 0,
            template_price: 0,
            hangers_price: 0,
            total_price: 0,
            price_with_discount: 0,
            rounded: {
                total: Math.round(0),
                discounted: Math.round(0),
            }
        },
        hangers_count: 0,
        price_in_pln: 0,
        frames_json: [{
            id: generateRandomId(),
            x: 0,
            y: 150,
            frame_top: 0,
            frame_left: 0,
            frame_material: "DIGITAL",
            frame_size: "B_15x15",
            image: "voucher 3.png",
            cropping: "2,0,2324,2322",
            filter: null,
            // passepartout_width: 0,
            passepartout_width_mm: 0,
            calculated: {
                image_top: 0,
                image_left: 0,
                image_width: 150,
                image_height: 150,
                frame_width: 150,
                frame_height: 150,
                dpi: 221,
            }
        }]
    }
}